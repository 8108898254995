.general-information {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;

    label {
        margin-top: 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .optional-tag {
            color: #EAF2FF;
            background-color: #4DA9FF;
            font-size: 10pt;
            width: fit-content;
            height: fit-content;
            padding: 4px 8px;
            border-radius: 7px;
            margin-left: 10px;
        }
    }

    #title, #time {
        margin-top: 0;
    }

    @media only screen and (max-width: 740px) {
        #time {
            margin-top: 30px;
        }
    }

    .validation-fade {
        color: red;
        font-family: 'Inter', sans-serif;
        margin-top: 5px;

        animation: fade-in-validation 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        @keyframes fade-in-validation {
            0% {
                transform: translateY(5px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
          }
    }

    .double-input {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        input, select {
            max-width: 50%;
            width: 50%;
            margin-bottom: 0;
        }

        &.time {

            select {
                margin-left: 15px;
                margin-right: 0;
            }
        }

        &.yield {
            flex-direction: column;
            align-items: flex-start;

            .inputs {
                display: flex;
                width: 100%;

                input, select {
                    max-width: 50%;
                    width: 50%;
                    margin-bottom: 0;
                }
        
                select {
                    margin-right: 15px;
                }
            }

            .help-text {
                margin-top: 5px;
                font-size: 10pt;
                color: grey;
                font-style: italic;
                text-align: left;
            }
        }
    }

    .quad-input-time {
        display: flex;
        flex-direction: column;
        width: 100%;

        div {
            display: flex;
            justify-content: space-between;
        }

        div:last-of-type {
            margin-top: 15px;
        }

        input, select {
            max-width: 50%;
            width: 50%;
            margin-bottom: 0;
        }

        select {
            margin-left: 15px;
        }
    }

    .checkbox-container {
        display: flex;
        align-items: center;
        margin-top: 15px;

        input {
            margin-bottom: 0;
            margin-right: 10px;
        }

        span {
            font-size: 14pt;
            font-weight: 300;
        }
    }

    select {
        text-align-last: center;
    }
    
    .left-column {
        display: flex;
        flex-direction: column;
        width: 48%;
        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        @keyframes slide-in-bottom {
            0% {
                        transform: translateY(50px);
                opacity: 0;
            }
            100% {
                        transform: translateY(0);
                opacity: 1;
            }
        }
    }

    .right-column {
        display: flex;
        flex-direction: column;
        width: 48%;
        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        @keyframes slide-in-bottom {
            0% {
                        transform: translateY(50px);
                opacity: 0;
            }
            100% {
                        transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .general-information {
        flex-direction: column;
        margin-top: 25px;

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 22pt;
            font-weight: 700;
            margin: 0;
            margin-bottom: 25px;
        }

        .left-column {
            width: 100%;
            margin-right: 0;
        }
    
        .right-column {
            width: 100%;
        }
    }
}