.cuisine-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 30px;
}

.cuisine-tile {
    text-decoration: none;
}

// Styles for cusine individual tiles are declared in Categories.scss

@media only screen and (max-width: 485px) {
    .cuisine-tiles {
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
    }
}