.search-page {
    h2 {
        margin-bottom: 15px;
    }

    .global-search {
        height: 75px;
        border-radius: 100px;
        font-size: 18pt;
        font-weight: 300;
        width: calc(100% - 150px);
        padding-right: 125px;
    }

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: 75px;
        max-width: 1250px;

        .global-search {
            position: absolute;
            max-width: 1250px;
        }

        #kitch-search {
            position: absolute;
            z-index: 100;
            font-size: 18pt;
            color: #a5a5a5;
            font-weight: 300;
            padding: 0 25px;
        }

        #clear-search {
            position: absolute;
            display: flex;
            align-items: center;
            right: 0;
            background-color: #9BCFFF;
            z-index: 100;
            font-size: 14pt;
            padding: 10px 20px;
            padding-right: 20px;
            border-radius: 50px;
            margin-right: 20px;
            cursor: pointer;
            transition: background-color 0.4s ease;

            body.hasHover &:hover {
                background-color: #b8dcff;
            }

            svg {
                font-size: 16pt;
            }
        }
    }

    .effect {
        animation: flip-switch 0.6s ease both;

        @keyframes flip-switch {
            0% {
                transform: rotateX(-100deg);
                opacity: 0;
            }
            100% {
                transform: rotateX(0deg);
                // filter: blur(0);
                opacity: 1;
            }
        }
    }

    .odd-effect {
        animation: odd-flip-switch 0.6s ease both;

        @keyframes odd-flip-switch {
            0% {
                transform: rotateX(-100deg);
                opacity: 0;
            }
            100% {
                transform: rotateX(0deg);
                // filter: blur(0);
                opacity: 1;
            }
        }
    }

    .search-results {
        max-width: 1250px;
    }

    h3 {
        font-family: 'Inter', sans-serif;
        font-size: 16pt;
        font-weight: 300;
    }

    h4 {
        font-family: 'Inter', sans-serif;
        font-size: 14pt;
        margin-bottom: 15px;
        font-weight: 400;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        display: inline-block;

        svg {
            margin-right: 5px;
            font-size: 20pt;
            color: rgb(110, 61, 255);
        }   
    }

    .favorites {
       
        svg {
            color: #ffa1a1;
        }
    }

    .trending-recipes {
        display: grid;
        grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
        column-gap: 15px;
        max-width: 975px;
        animation: fade-in-fwd 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-fwd {
            0% {
                transform: translateY(10px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .recipe-result-card {
            padding: 15px;
            margin-top: 0;
            width: calc(100% - 30px);
            max-width: 450px;
        }
    }

    @media only screen and (max-width: 740px) {
        .trending-recipes {
            grid-template-columns: 100%;
            row-gap: 15px;
    
            .recipe-result-card {
                padding: 15px;
                margin-top: 0;
                width: calc(100% - 30px);
                max-width: 800px;
            }
        }
    }

    .tab-container {
        display: flex;
        align-items: center;
        // justify-content: space-around;
        animation: fade-in-fwd 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        max-width: 1300px;

        @keyframes fade-in-fwd {
            0% {
                transform: translateY(10px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .minute-tab {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            color: white;
            background-color: black;
            font-family: 'Inter', sans-serif;
            padding: 20px 20px;
            border-radius: 50px;
            white-space: nowrap;
            transform: scale(1);
            cursor: pointer;
            transition: transform 0.3s ease;

            body.hasHover &:hover {
                transform: scale(1.05);
            }

            &#middle-minute-tab {
                margin: 0 10px;
            }

            .inner-tab-wrapper {
                display: flex;
                align-items: center;
            }

            p {
                margin: 0;
                margin-left: 40px;
                white-space: break-spaces;
            }

            span {                
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    font-size: 36pt;
                    position: absolute;
                }
            }


        }

        @media only screen and (max-width: 642px) {
            .minute-tab {
                padding: 10px 20px;
            }
        }
    }

    .results-section-header {
        font-family: 'Inter', sans-serif;
        font-size: 18pt;
        font-weight: 500;
    }

    .recipe-search-results, .time-search-results, .cuisine-search-results, .category-search-results, .substitution-search-results {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
        column-gap: 15px;
        row-gap: 15px;
        margin-top: 15px;
        margin-bottom: 35px;

        &.no-results {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .recipe-result-card {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        background-color: #9BCFFF;
        border-radius: 25px;
        padding: 15px;
        width: calc(100% - 30px);
        cursor: pointer;

        .tile-content {
            display: flex;
            width: 100%;
        }

        .thumb-image {
            height: 100px;
            img {
                height: 100px;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                border-radius: 10px;
                background-color: #b8dcff;
            }
        }

        .title-box {
            display: flex;
            position: relative;
            justify-content: space-between;
            align-self: flex-start;
            flex-direction: column;
            margin-left: 15px;
            height: 100px;
            width: calc(100% - 130px);

            section {
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                .tile-recipe-title {
                    font-family: 'Bitter', serif;
                    font-size: 22pt;
                    font-weight: 300;
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    width: 100%;
                }
    
                h4 {
                    font-family: 'Inter', sans-serif;
                    font-size: 12pt;
                    font-weight: 300;
                    margin: 0;
                }
            }

            .fade-chip-block {
                position: absolute;
                bottom: 3px;
                right: 0px;
                height: 30px;
                width: 45px;
                z-index: 10;
                background: rgb(155,207,255);
                background: linear-gradient(90deg, rgba(155,207,255,0) 0%, rgba(155,207,255,1) 100%);
            }

            .recipe-tags {
                padding-bottom: 15px;
                margin-bottom: -10px;
                flex-wrap: nowrap;
                display: flex;
                white-space: nowrap;
                overflow: scroll;
                position: relative;
                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                .chip {
                    margin-right: 5px;
                }

                .chip:last-of-type {
                    margin-right: 30px;
                }
            }

            /* Hide scrollbar for Chrome, Safari and Opera */
            .recipe-tags::-webkit-scrollbar {
                display: none;
            } 
        }

        .result-tile-arrow {
            position: absolute;
            right: 10px;
            font-size: 24pt;
            color: #4DA9FF;
            transform: translateX(0);
            transition: transform 0.4s ease;
        }

        body.hasHover &:hover {
            .result-tile-arrow {
                transform: translateX(7px);
            }
        }
    }

    .cat-cui-result-card {
        position: relative;
        display: flex;
        align-items: center;
        background-color: #9BCFFF;
        border-radius: 25px;
        padding: 15px;
        width: calc(100% - 30px);
        cursor: pointer;
        text-decoration: none;

        img {
            height: 100px;
            cursor: pointer;
        }

        label {
            font-family: 'Bitter', sans-serif;
            font-weight: 300;
            font-size: 18pt;
            margin-left: 10px;
            cursor: pointer;
            color: black;
            margin-right: 25px;
        }

        .result-tile-arrow {
            position: absolute;
            right: 10px;
            font-size: 24pt;
            color: #4DA9FF;
            transform: translateX(0);
            transition: transform 0.4s ease;
        }

        body.hasHover &:hover {
            .result-tile-arrow {
                transform: translateX(7px);
            }
        }
    }

    .substitution-search-results {
        .cat-cui-result-card {
            img {
                margin: 0 12.5px;
            }
        }
        
    }

    .active-filter-chip {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        padding-right: 15px;
        margin-bottom: 25px;
        background-color: #9BCFFF;
        width: fit-content;
        border-radius: 25px;
        font-family: 'Inter', sans-serif;

        h5 {
            font-family: 'Inter', sans-serif;
            margin: 0;
            font-size: 12pt;
            margin-right: 5px;
        }
        

        svg {
            margin-right: 5px;
            font-size: 24pt;
            transition: color 0.3s ease;
            cursor: pointer;

            &:hover {
                color: #606060;
            }
        }
    }
}

@media only screen and (max-width: 500px) {

    .search-page {
        .tab-container {
            column-gap: 5px;
            justify-content: space-between;
    
            .minute-tab {
                padding: 15px 10px;

                &#middle-minute-tab {
                    margin: 0 5px;
                }
    
                p {
                    font-size: 10pt;
                    margin-left: 30px;
                }
    
                span {
                    scale: 0.75;
                }
            }
        } 
    }
}

@media only screen and (max-width: 424px) {

    .search-page {

        .tab-container {
    
            .minute-tab {
                padding: 5px 10px;
            }
        } 
    }
}

@media only screen and (max-width: 740px) {
    .search-page {
    
        .global-search {
            height: 55px;
            border-radius: 100px;
            font-size: 14pt;
            font-weight: 300;
        }

        .input-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            height: 55px;
    
            .global-search {
                position: absolute;
            }
    
            #kitch-search {
                position: absolute;
                z-index: 100;
                font-size: 14pt;
                color: #a5a5a5;
                font-weight: 300;
                padding: 0 25px;
            }

            #clear-search {
                font-size: 12pt;
                padding: 7.5px 7.5px;
                margin-right: 12px;
            }
        }
    }
}