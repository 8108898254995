.invite {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 120px;
        width: 86.25px;
        margin-top: 25px;
    }

    h2 {
        font-weight: 200;
        font-size: 48pt;
        margin-bottom: 25px;
        margin-top: 0;
    }

    .access-instructions {
        max-width: 700px;
        text-align: center;
        margin-bottom: 25px;
        color: #606060;
    }

    .access-code {
        display: flex;

        input {
            width: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 48pt;
            text-align: center;
            font-family: 'Bitter', monospace;
            font-weight: 300;
            height: 100px;
            border-radius: 20px;
            margin: 10px;
            padding: 5px;
            caret-color: transparent;
        }
    }

    .alert {
        font-family: 'Inter', sans-serif;
        background-color: red;
        color: white;
        padding: 5px 25px;
        margin-bottom: 15px;
        border-radius: 25px;
        display: none;
        text-align: center;

        &.error {
            height: fit-content;
            display: inline;
            animation: alert-animation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes alert-animation {
                0% {
                  -webkit-transform: translateY(10px);
                          transform: translateY(10px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                  opacity: 1;
                }
              }
              
        }
    }

    @media only screen and (max-width: 750px) {
        h2 {
            font-size: 10vw;
            font-weight: 300;
        }
        .access-code {
            input {
                width: 40px;
                height: 60px;
                border-radius: 15px;
                margin: 5px;
                font-size: 28pt;
            }
        }
    }

    @media only screen and (max-width: 625px) {
        .alert {
            border-radius: 15px;
        }
    }

    @media only screen and (max-width: 600px) {
        img {
            height: 100px;
            width: 71.9px;
        }
    }

    @media only screen and (max-width: 400px) {

        .access-code {
            input {
                width: 30px;
                height: 50px;
                border-radius: 15px;
                margin: 3px;
                font-size: 22pt;
            }
        }
    }

    

    button {
        display: flex;
        align-items: center;
        margin-top: 25px;

        svg {
            font-size: 16pt;
        }
    }

    a {
        text-decoration: none;
        color: #4DA9FF;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }
}