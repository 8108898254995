.not-found {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    img {
        height: 120px;
    }

    h1 {
        font-family: 'Inter', sans-serif;
        font-size: 60pt;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        font-size: 16pt;
        font-weight: 300;
        margin: 0;
    }

    button {
        margin-top: 15px;
    }
}