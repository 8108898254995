.substitution-loading {
    max-width: 800px;
    margin-bottom: 100px;

    .mitch-kitch-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30vh;
    }
}

.guide-credit {
    a {
        display: flex;
        align-items: center;
        width: fit-content;

        svg {
            margin-right: 5px;
        }
    }
}

.sub-guide {
    max-width: 800px;
    margin-bottom: 100px;

    h2 {
        margin-bottom: 0;
    }

    span {
        a {
            text-decoration: none;
            font-family: 'Inter', sans-serif;
            font-size: 12pt;
            font-style: italic;
            color: #73bbff;
            font-weight: 500;
        }
    }

    .intro {
        margin-top: 30px;
        font-family: 'Bitter', serif;
        font-weight: 300;
        font-size: 18pt;
    }

    .intro-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        img {
            width: 90%;
            max-width: 500px;
        }
    }

    .quick-jump-instructions {
        font-size: 16pt;
        font-weight: 400;
        margin-top: 50px;
        margin-bottom: 15px;
        font-style: italic;
    }

    [id] {
        scroll-margin-top: 100px;
    }

    .quick-jump-tiles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-evenly;
        margin-left: -10px;
        margin-right: -10px;

        .quick-jump-link {
            text-decoration: none;
            animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            --index: 0;
            animation-delay: calc(0.04s * var(--index));

            @keyframes fade-in-bottom {
                0% {
                    transform: translateY(50px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
              
        }

        .sub-tile {
            background-color: #9BCFFF;
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 25px;
            margin: 0 10px 20px 10px;
            transition: transform 0.3s ease;
            height: 200px;

            span {  
                text-align: center;
                font-family: 'Bitter', sans-serif;
                font-weight: 300;
                font-size: min(3vw, 14pt);
                margin-top: 10px;
                color: black;
            }

            body.hasHover &:hover {
                transform: scale(1.03);
            }
        }
    }

    @media only screen and (max-width: 740px) {
        .quick-jump-tiles {
            grid-template-columns: 1fr 1fr;

            .sub-tile {
                margin: 0 10px 20px 10px;
                transition: transform 0.3s ease;
                height: fit-content;
    
                span {  
                    font-size: min(4vw, 18pt);
                }
            }
        }
    }

    .category-section {

        svg {
            font-size: 24pt;
        }

        h3 {
        font-family: 'Inter', sans-serif;
        font-size: 22pt;
        font-weight: 600;
        margin-top: 65px;
        }

        p {
            font-family: 'Bitter', serif;
            font-weight: 300;
            font-size: 18pt;
            margin-bottom: 0px;
        }

        .section-content {
            h4 {
                font-family: 'Inter', sans-serif;
                font-size: 16pt;
                font-weight: 600;
                margin-bottom: 0px;
            }

            p {
                font-family: 'Inter', serif;
                font-weight: 300;
                font-size: 14pt;
                margin-top: 10px;
                margin-bottom: 30px;

                &.spice {
                    margin-top: 35px;
                }
            }

            table {
                font-family: 'Inter', sans-serif;
                text-align: left;
                margin-top: 25px;
                border-collapse: collapse;

                th, td {
                    padding: 10px 35px 10px 0;

                    &:last-of-type {
                        padding-right: 0;
                    }

                    &.tableItem {
                        font-weight: 600;
                    }
                }

                tr {
                    border-bottom: 2px solid #9BCFFF;
                }
                
            }
        }
    }
}
