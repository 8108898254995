.category-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 30px;
}

.category-tile {
    text-decoration: none;
}

.cat-cui-header {
    margin-bottom: 0;
}

.go-back {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #73bbff; 
    font-weight: 500;
    font-style: normal;
    margin-bottom: 5px;
    transition: color 0.4s ease;
    margin-bottom: 25px;
    width: fit-content;
    font-family: 'Inter', sans-serif;
    cursor: pointer;

    body.hasHover &:hover {
        color: #b4dbff;
    }

    svg {
        margin-right: 5px;
        font-size: 14pt;
    }
}

.deep-link-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30vh;
}

// Changes to .tile class will also affect Cuisine tiles.
.tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: unset;
    transition: transform 0.3s ease;
    background-color: #9BCFFF;
    aspect-ratio: 1 / 1;
    width: 100%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;


    .sketch {
        height: 80%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;  
        }
    }

    .title {
        font-family: 'Bitter', serif;
        font-size: 16pt;
        font-weight: 300;
        margin: 20px 0;
        margin-top: 0;
        color: black;
    }

    body.hasHover &:hover {
        transform: scale(1.03);
        z-index: 50;
    }
}

.cat-cui-not-found {
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    color: #606060;
    font-style: italic;
    animation: slide-in-not-found 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @keyframes slide-in-not-found {
        0% {
            transform: translateY(20px);
            
            
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    span, a {
        margin-bottom: 10px;
    }

    a {
        font-style: normal;
        font-family: 'Inter', sans-serif;
        margin-left: 10px;
        color: #73bbff;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.4s ease;
        cursor: pointer;

        body.hasHover &:hover {
            color: #b4dbff;
        }
    }
}

@media only screen and (max-width: 740px) {
    .cat-cui-not-found {
        flex-direction: column;
        align-items: flex-start;

        a {
            margin-left: 0;
        }
    }
}

.cat-cui-loader {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 485px) {
    .category-tiles {
        grid-template-columns: 1fr 1fr;
        column-gap: 15px;
        row-gap: 15px;
    }

    .tile {
        .title {
            font-size: 14pt;
        }
    }
}