.print-interface {
    height: 100%;
    position: relative;

    .print-instructions {
        text-align: left;
        display: block;
        margin-bottom: 25px;
    }

    .print-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        .toggles {
            display: flex;

            .toggle-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                label {
                    text-align: left;
                }
    
                #colorSwitch, #scaledSwitch {
                    margin-top: 5px;
                }

                .scale-helper-text {
                    font-size: 10pt;
                    color: #606060;
                }
                &.scale {
                    margin-right: 25px;
                }
            }
        }

        .color {
            margin-right: 35px;
        }

        .print-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            height: 45px;
            border: none;
            background-color: #9BCFFF;
            padding: 0 35px;
            border-radius: 50px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            font-size: 12pt;
            color: black;
            -webkit-appearance: initial;
        
            body.hasHover &:hover {
                background-color: #b4dbff;
            }
         }
    }

    .pdf-preview {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        flex-grow: 1;
        overflow-y: hidden;

        label {
            padding-bottom: 10px;
        }

        #recipe-pdf {
            max-width: calc(100% - 54px);
            border-radius: 15px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 2px solid #9BCFFF80;
            margin-top: 0;
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 550px) {
    .print-interface {
    
        .print-controls {
            // align-items: flex-end;
            flex-direction: column;
            align-items: flex-start;
            
            .print-button {
                align-self: flex-end;
                margin-top: 15px;
            }
        }
    }
    
}