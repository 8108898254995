.entry {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 120px;
        width: 86.25px;
        margin-top: 25px;
    }

    h2 {
        font-weight: 200;
        font-size: 48pt;
        margin-bottom: 25px;
        margin-top: 0;
        text-align: center;
    }

    .reset-confirmation {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        button {
            margin: 0;
        }
    }

    .reset-instructions {
        text-align: center;
        margin-bottom: 25px;
        color: #606060;
    }

    &.sign-in {
        input {
            margin-top: 10px;
            margin-bottom: 5px;
            width: 80%;
            max-width: 350px;
            padding: 0 25px;
        }
    }

    .input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        width: 90%;
        max-width: 400px;
        padding: 0 25px;

        input {
            width: calc(100% - 50px);
        }

        label {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    input:last-of-type {
        margin-bottom: 15px;
    }

    button {
        display: flex;
        align-items: center;
        margin-top: 25px;

        svg {
            font-size: 16pt;
        } 
    }

    a {
        text-decoration: none;
        color: #4DA9FF;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }

    section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    #name, #email {
        margin-bottom: 35px;
    }

    .alert {
        font-family: 'Inter', sans-serif;
        background-color: red;
        color: white;
        padding: 5px 25px;
        margin-bottom: 15px;
        border-radius: 50px;
        display: none;
        text-align: center;
        
        height: 0;

        &.error {
            height: fit-content;
            display: block;
            animation: alert-animation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes alert-animation {
                0% {
                  -webkit-transform: translateY(10px);
                          transform: translateY(10px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                  opacity: 1;
                }
              }
              
        }
    }

    @media only screen and (max-width: 600px) {
        img {
            height: 100px;
            width: 71.9px;
        }
        h2 {
            font-size: 34pt;
        }

        .alert {
            border-radius: 15px;
        }
    }
}