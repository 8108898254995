.mitch-kitch-loader {
    .mobile-spinner {
        svg {
            stroke-linecap: round;
        }
    }
}

.mitch-kitch-button-loader {

    .mobile-spinner {
        svg {
            stroke-linecap: round;
        }
    }
}