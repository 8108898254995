.edit-page {

    .no-recipe-edit {

        .no-recipe-text {
            font-size: 16pt;
            font-weight: 300;
            font-family: 'Bitter', serif;

            #edit-button-sample {
                display: inline-block;
                margin: 0 10px;
                background-color: #9BCFFF;
                font-size: 10pt;
                font-weight: 400;
                padding: 7px 15px;
                border-radius: 50px;
                width: fit-content;
            }
        }
    }

    .edit-loader {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .edit-header-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
            font-size: 18pt;
        }

        button {
            display: flex;
            align-items: center;
            padding: 0 25px;
            text-decoration: none;
        }

        .back-button {
            svg {
                font-size: 22pt;
                margin-right: 5px;
            }
        }

        .secondary {
            display: flex;
        }

        .cancel-edit {
            background-color: #dadada;
            transition: background-color 0.4s ease;

            body.hasHover &:hover {
                background-color: #e0e0e0;
            }
        }

        .delete-recipe {
            background-color: #ffa1a1;
            transition: background-color 0.4s ease;
            margin: 0 10px;

            body.hasHover &:hover {
                background-color: #ffb7b7;
            }
        }
    }

    .edit-header {
        font-weight: 500;
        color: #4DA9FF;
    }

    h2 {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 24pt;
    }

    .author-name {
        font-weight: 600;
    }

    #confirm-delete {
        font-family: 'Inter', sans-serif;
        font-size: 14pt;
        
        div {
            display: flex;
            align-items: center;
            svg {
                margin-right: 10px;
                font-size: 24pt;
                color: #4DA9FF;
                display: flex;
                align-items: center;
                margin-top: 2px;
            }
        }

        .delete-recipe {
            background-color: #ffa1a1;
            transition: background-color 0.4s ease;
            margin-left: 10px;
            margin-top: 10px;

            body.hasHover &:hover {
                background-color: #ffb7b7;
            }
        }
    }
}