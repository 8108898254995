.instructions-tab {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;

    .instructions-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 30px;

        &.animated-ins-section-entry {
            animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes slide-in-bottom {
                0% {
                    transform: translateY(50px);
                    height: 0;
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    height: 416px;
                    opacity: 1;
                }
            }
        }
        

        .validation-fade {
            color: red;
            font-family: 'Inter', sans-serif;
            margin-top: 5px;
    
            animation: fade-in-validation 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            @keyframes fade-in-validation {
                0% {
                    transform: translateY(5px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        &.slide-out-blurred-left {
            animation: slide-out-blurred-left 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        @keyframes slide-out-blurred-left {
            0% {
                transform: translateX(0) scaleY(1) scaleX(1) translateY(-100%);
                height: 416px;
                filter: blur(0);
                opacity: 1;
            }
            100% {
                transform: translateX(-1000px) scaleX(2) scaleY(0.2) translateY(0);
                height: 0;
                filter: blur(40px);
                opacity: 0;
            }
        }

        .section-title-span {
            display: flex;
            align-items: center;

            input {
                margin-right: 10px;
                margin-bottom: 0;
            }
        }

        label {
            // margin-top: 30px;
            margin-bottom: 10px;
        }

        label:first-of-type {
            margin-top: 0;
        }
    
        input, textarea, select {
            width: 30%;
        }

        .instruction-items {
            margin-left: 75px;
            margin-bottom: 20px;
            margin-top: 30px;

            .stacked-instruction-input {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &.animated-instruction-entry {
                    animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    @keyframes slide-in-bottom {
                        0% {
                            transform: translateY(50px);
                            height: 0;
                            opacity: 0;
                        }
                        100% {
                            transform: translateY(0);
                            height: 200px;
                            opacity: 1;
                        }
                    }
                }

                &.slide-out-blurred-left {
                    animation: slide-out-blurred-left 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                }

                @keyframes slide-out-blurred-left {
                    0% {
                        transform: translateX(0) scaleY(1) scaleX(1);
                        height: 200px;
                        filter: blur(0);
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                        height: 0;
                        filter: blur(40px);
                        opacity: 0;
                    }
                }

                .inputs {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;

                    input {
                        margin-right: 10px;
                        width: 150px;
                    }
    
                    textarea {
                        width: 450px;
                        margin-top: 10px;
                    }
                }
            }

            .stacked-instruction-input:first-of-type {
                margin-top: 10px;
            }
        }
    }

    .add-new-item {
        font-size: 14pt;
        font-weight: 300;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.3s ease;
        width: fit-content;
        margin-top: 30px;

        svg {
            font-size: 26pt;
            margin-right: 10px;
            color: #4DA9FF;
            transition: color 0.3s ease;
        }

        body.hasHover &:hover {
            color: #606060;

            svg {
                color: #9BCFFF;
            }
        }
    }

    .remove-item {
        font-size: 26pt;
        color: #9BCFFF;
        height: 45px;
        transition: color 0.3s ease;
        cursor: pointer;

        body.hasHover &:hover {
            color: #c12d2d;
        }
    }
}

@media only screen and (max-width: 740px) {
    .instructions-tab {
        margin-top: 25px;
        width: 100%;

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 22pt;
            font-weight: 700;
            margin: 0;
            margin-bottom: 25px;
        }

        .instructions-section {

            .section-title-span {
                width: 100%;
                max-width: 300px;
    
                input {
                    margin-bottom: 0;
                    width: 100%;
                }
            }

            &:first-of-type {
                .section-title-span {
                    width: calc(100% - 43px);
                }
            }
    
            .instruction-items {
                margin-left: 35px;
    
                .stacked-instruction-input {
                    margin-top: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: calc(100%);

                    &.animated-instruction-entry {
                        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
                        @keyframes slide-in-bottom {
                            0% {
                                transform: translateY(50px);
                                height: 0;
                                opacity: 0;
                            }
                            100% {
                                transform: translateY(0);
                                height: 200px;
                                opacity: 1;
                            }
                        }
                    }
    
                    &.slide-out-blurred-left {
                        animation: slide-out-blurred-left 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                    }
    
                    @keyframes slide-out-blurred-left {
                        0% {
                            transform: translateX(0) scaleY(1) scaleX(1);
                            height: 200px;
                            transform-origin: 50% 50%;
                            filter: blur(0);
                            opacity: 1;
                        }
                        100% {
                            transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                            height: 0;
                            transform-origin: 100% 50%;
                            filter: blur(40px);
                            opacity: 0;
                        }
                    }
    
                    .inputs {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
    
                        input {
                            margin-right: 0;
                            width: calc(50% - 50px);
                        }
        
                        textarea {
                            width: calc(100% - 50px);
                        }

                        
                    }

                    &:first-of-type {
                        textarea {
                            width: calc(100% - 85px);
                        }
                    }
                }
            }
        }
    }
}