.recipes-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //annoying equalizing margin to standardize page header (other pages use inline-block display)
    h2 {
        margin-top: 5.987px;
    }

    //another adjustment margin to properly center button with header
    button {
        margin-bottom: 18px;
    }
}

.recipe-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    column-gap: 20px;
    row-gap: 20px;

    &.loading {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
    }
}

@media only screen and (max-width: 885px) {
    .recipe-cards {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
}

.recipe-link-card {
    text-decoration: none;
    width: 100%;
    cursor: pointer;
}

.recipe-card-container {
    width: 100%;
    background-color: #9BCFFF;
    border-radius: 25px;
    position: relative;
    transition: all 0.3s ease;
    z-index: unset;
    
    .recipe-thumbnail {
        position: relative;
        display: flex;
        justify-content: center;

        img {
            width: calc(100% - 40px);
            margin: 20px;
            margin-bottom: 10px;
            border-radius: 15px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            background-color: #b8dcff;
        }

        sl-skeleton {
            width: calc(100% - 40px);
            margin: 20px;
            margin-bottom: 10px;
            border-radius: 15px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            background-color: #b8dcff;
        }
    }

    .recipe-title {
        margin: 20px;
        margin-top: 0;
        margin-bottom: 10px;
        width: calc(100% - 40px);

        h3 {
            font-size: 22pt;
            font-weight: 300;
            color: black;
            margin-top: 0;
            margin-bottom: 0px;
        }
    }

    .fade-chip-block {
        position: absolute;
        bottom: 15px;
        right: 19px;
        height: 30px;
        width: 45px;
        z-index: 10;
        background: rgb(155,207,255);
        background: linear-gradient(90deg, rgba(155,207,255,0) 0%, rgba(155,207,255,1) 100%);
    }

    .recipe-tags {
        display: flex;
        width: calc(100% - 40px);
        margin: 0 20px;
        padding-bottom: 10px;
        flex-wrap: nowrap;
        overflow: scroll;
        position: relative;
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        .chip {
            margin-right: 5px;
            margin-bottom: 5px;
            white-space: nowrap;
        }

        .chip:last-of-type {
            margin-right: 30px;
        }
    }

    .recipe-tags::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
    }
}

body.hasHover .recipe-card-container:hover {
    transform: scale(1.03);
    z-index: 50;
}

@media only screen and (max-width: 740px) {
    .recipe-card-container {
    
        .recipe-thumbnail {
    
            img {
                width: calc(100% - 30px);
                margin: 15px;
                margin-bottom: 10px;
                border-radius: 10px;
                aspect-ratio: 16 / 9;
                object-fit: cover;
            }
        }
    
        .recipe-title {
            margin: 15px;
            margin-top: 0;
            margin-bottom: 10px;
            width: calc(100% - 30px);
    
            h3 {
                font-size: 22pt;
                margin-top: 0;
                margin-bottom: 0px;
            }
        }
    
        .recipe-tags {
            width: calc(100% - 30px);
            margin: 15px;
            margin-top: 0px;
            padding-bottom: 10px;
            margin-bottom: 0px;
    
            .chip {
                margin-right: 5px;
                margin-bottom: 5px;
            }
        }
    }
}

.filter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 501;
    width: 100vw;
    height: 100%;
    background-color: #ffffff70;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: backdrop-filter 0.3s ease;

    .filter-container {
        background-color: #9BCFFF;
        border-radius: 25px;
        width: 80%;
        max-width: 700px;
        padding: 25px;
        margin-bottom: 35vh;
        animation: slide-in-blurred-bottom 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;

        @keyframes slide-in-blurred-bottom {
            0% {
                transform: translateY(500px) scaleY(2) scaleX(0.8);
                transform-origin: 50% 100%;
                filter: blur(40px);
                opacity: 0;
            }
            100% {
                transform: translateY(0) scaleY(1) scaleX(1);
                transform-origin: 50% 50%;
                filter: blur(0);
                opacity: 1;
            }
        }
          

        .modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;

            h2 {
                font-family: 'Inter';
                font-size: 22pt;
                font-weight: 300;
                margin: 0;
            }
            span {
                cursor: pointer;
            }

            svg {
                font-size: 22pt;;
            }
        }

    }
}

@media only screen and (max-width: 740px) {
    .filter-overlay {
        align-items: flex-start;
        justify-content: center;
    
        .filter-container {
            margin-top: 100px;
    
            .modal-header {
    
                h2 {
                    font-size: 18pt;
                }
    
                svg {
                    font-size: 18pt;;
                }
            }
    
        }
    }
}

