.add-new-start-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15vh;
    animation: slide-in-start 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    @keyframes slide-in-start {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .scan-recipe-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;

        .beta-chip {
            color: #EAF2FF;
            background-color: #4DA9FF;
            font-size: 10pt;
            width: fit-content;
            height: fit-content;
            padding: 2px 8px;
            border-radius: 7px;
            margin-bottom: 10px;
        }

        label {
            height: 45px;
            border: none;
            display: flex;
            background-color: #9BCFFF;
            padding: 0 35px;
            border-radius: 50px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            font-size: 12pt;
            color: black;
            -webkit-appearance: initial;

            body.hasHover &:hover {
                background-color: #b4dbff;
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                color: black;
            }
        }

        input[type="file"] {
            display: none;
            height: 0;
        }
        
    }

    span {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 12pt;
        color: #73bbff; 
        cursor: pointer; 
    }
}

.file-upload-error {
    display: flex;
    align-items: center;

    svg {
        font-size: 24pt;
        color: #dc2626;
    }
}

sl-alert::part(base) {
    border-radius: 15px;
    position: absolute;
    top: 70px;
    right: 15px;
    margin-left: 15px;
}

sl-alert {
    position: absolute;
    top: 0;
    right: 0;
}

.scan-step-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    animation: slide-in-step-two 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    margin: 0 15px;

        @keyframes slide-in-step-two {
            0% {
                transform: translateY(50px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

    .beta-chip {
        color: #EAF2FF;
        background-color: #4DA9FF;
        font-size: 10pt;
        width: fit-content;
        height: fit-content;
        padding: 2px 8px;
        border-radius: 5px;
    }

    h3 {
        font-family: 'Inter', sans-serif;
        font-size: 16pt;
        font-weight: 300;
        line-height: 2rem;
        text-align: center;
        margin-top: 10px;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            margin-right: 5px;
            font-size: 16pt;
        }

        &.hidden {
            animation: hide-button 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes hide-button {
                0% {
                    transform: translateY(0);
                    opacity: 1;
                }
                100% {
                    transform: translateY(50px);
                    opacity: 0;
                }
            }
        }
    }

    .svg-indicator {
        display: none;
        width: 100px;
        height: 100px;
        animation: slide-in-loop 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes slide-in-loop {
            0% {
                
                transform: translateZ(-50px) rotate(-90deg);
                opacity: 0;
            }
            100% {
                transform: translateZ(0) rotate(-90deg);
                opacity: 1;
            }
        }
      }
      
      /* 
       * Add the center and the radius using the size 
       * and `stroke-width` values.
       */
      .svg-indicator-track,
      .svg-indicator-indication {
        cx: 50px;
        cy: 50px;
        r: 40px;
        fill: transparent;
        stroke-width: 15px;
      }
      
      /* Some color to highlight the rings */
      .svg-indicator-track {
        stroke: #ffffff;
      }
      
      .svg-indicator-indication {
        --progress: 0;
        stroke: #9BCFFF;
        stroke-dasharray: 251.2px;
        stroke-dashoffset: 0;
        stroke-dashoffset: var(--progress);
        stroke-linecap: round;
      }
}

.add-new-flow {
    max-width: 1400px;
    padding-bottom: 75px;

    &.edit-flow {
        margin-top: 25px;
    }

    .data-toggle {
        height: 45px;
        background-color: #9BCFFF;
        border-radius: 45px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
        width: 100%;
        padding: 0 5px;
        margin-left: -5px;

        svg {
            font-size: 18pt;
            height: unset;
            width: unset;
            margin-bottom: 0.25px;
        }

        .tab-background {
            position: absolute;
            left: 0;
            top: 0;
            background-color: #4DA9FF;
            width: 25%;
            height: 35px;
            border-radius: 45px;
            margin: 5px;
        }

        span {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease;
            z-index: 50;
            width: 25%;
            height: 100%;

            

            span {
                width: 100%;
            }
        }
    }

    .add-new-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 15px 25px;
        padding-bottom: 25px;
        width: calc(100% - 50px);
        background-color: #EAF2FF50;
        backdrop-filter: blur(4px);

        button {
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 30px;

            svg {
                font-size: 22pt;
                margin-left: 5px;
                margin-right: -5px;
            }

            &.add-new-back {
                padding: 0 20px;

                svg {
                    margin: 0;
                }
            }
        }

        #primary {
            width: 175px
        }
    }
}

sl-dialog::part(title) {
    font-weight: 600;
}

sl-dialog::part(base) {
    z-index: 500;
}

sl-dialog::part(overlay) {
    backdrop-filter: blur(5px);
    background-color: #ffffff30;
}

sl-dialog::part(panel) {
    border-radius: 15px;
}

#confirm-no-image, #confirm-navigate-away {
    font-family: 'Inter', sans-serif;

    div {
        display: flex;
        align-items: center;
        svg {
            margin-right: 10px;
            font-size: 24pt;
            color: #4DA9FF;
        }
    }

    font-size: 14pt;

    .add-photo-recipe {
        background-color: #4DA9FF;
        color: white;
        font-weight: 600;
        transition: background-color 0.4s ease;
        margin-left: 10px;
        margin-top: 10px;

        body.hasHover &:hover {
            background-color: #2e9aff;
        }
    }
}

@media only screen and (max-width: 740px) {
    .add-new-flow {
        .add-new-controls {
            padding: 15px;
            padding-bottom: 25px;
            width: calc(100% - 30px);
        }
    }
}