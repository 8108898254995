.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;

    h2 {
        font-weight: 300;
        font-size: 48pt;
        margin-top: 50px;
        margin-bottom: 25px;
        text-align: center;
    }

    .hero-buttons {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 7px;
        }

        .explore-catcui {
            width: 207px;
        }
    }

    .random-loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .substitution-link {
        text-decoration: none;
    }

    .substitution-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        background-color: transparent;
        border-radius: 25px;
        padding: 15px 25px;
        transition: background-color 0.4s ease;
        cursor: pointer;
        font-family: 'Bitter', sans-serif;
        font-size: 16pt;
        font-weight: 300;
        color: black;

        svg {
            font-size: 22pt;
            color: #4DA9FF;
            margin-bottom: 10px;
        }

        body.hasHover &:hover {
            background-color: white;
        }
    }
}

@media only screen and (max-width: 740px) {
    .landing-page {
        height: 65vh;

        .hero-buttons {
            flex-direction: column;
        }

        h2 {
            font-size: 36pt;
        }
    }
}

@media only screen and (max-width: 500px) {
    .landing-page {

        h2 {
            font-size: 28pt;
        }
    }
}