.desktop-menu {
    display: flex;
    align-items: center;

    ul {
        list-style: none;
        display: flex;
        text-decoration: none;

        li {
            padding-right: 15px;
            font-family: 'Inter', sans-serif;
            font-size: 10pt;
            padding-bottom: 3px;
    
            .desktop-menu-link {
                text-decoration: none;
                color: black;
                transition: color 0.3s ease;

                &.active {
                    color: #4DA9FF;
                }

                body.hasHover &:hover {
                    color: #606060;
                }

                body.hasHover &.active:hover {
                    color: #73bbff;
                }
            }
        }
    }

    .sign-out-button {
        height: 35px;
        padding: 0 20px;
        font-size: 10pt;
        margin-right: 15px;
    }

    .kitch-search {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .search-icon {
            font-size: 20pt;
            margin-right: 15px;
            margin-left: 5px;
            transition: color 0.3s ease;
            color: black;
            cursor: pointer;

            body.hasHover &:hover {
                color: #606060;
            }

            &.active {
                color: #4DA9FF;

                body.hasHover &:hover {
                    color: #73bbff;
                }
            }
        }
    }

    .add-button {
        
        a {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 24pt;
                color: black;
                cursor: pointer;
                transform: rotate(0);
                transition: color 0.3s ease, transform 0.3s ease;
        
                body.hasHover &:hover {
                    color: #606060;
                }
            }
        }

        &.active {
            a {
                svg {
                    transform: rotate(45deg);
                }
            }
        }
    }
}