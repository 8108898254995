.signup {

    

    .step-no {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 18pt;
        font-weight: 500;
        background-color: #9BCFFF;
        color: black;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
    }

    .name-sign-up {
        display: flex;

        .input-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0px;
            margin-right: 25px;
            width: 90%;
            max-width: 250px;
    
            label {
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }

    .input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        width: 90%;
        max-width: 525px;

        input {
            width: calc(100% - 50px);
        }

        label {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    #name, #email {
        margin-bottom: 50px;
    }

    .button-wrapper {
        width: 90%;
        max-width: 525px;
        display: flex;
        justify-content: center;
    }

    button {
        display: flex;
        align-items: center;
        margin-top: 50px;

        svg {
            font-size: 16pt;
        }
    }

    .alert {
        font-family: 'Inter', sans-serif;
        background-color: red;
        color: white;
        padding: 5px 25px;
        margin-bottom: 15px;
        border-radius: 50px;
        display: none;
        width: fit-content;
        margin: auto;
        text-align: center;
        
        height: 0;

        &.error {
            height: fit-content;
            display: block;
            animation: alert-animation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes alert-animation {
                0% {
                  -webkit-transform: translateY(10px);
                          transform: translateY(10px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                  opacity: 1;
                }
              }
              
        }

        &.signup {
            background-color: #606060;
            padding: 10px 25px;
        }
    }

    @media only screen and (max-width: 625px) {
        .name-sign-up {
            flex-direction: column;

            .input-group {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 15px;
                margin-right: 0;
                width: 100%;
                max-width: 400px;
            }
        }

        .alert {
            border-radius: 15px;
        }

        .input-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            margin-right: 0;
            width: 100%;
            max-width: 400px;
        }

        .button-wrapper {
            width: 100%;
        }
    }
}