.pictures-tab {
    display: flex;
    margin-top: 50px;
    width: 100%;

    .image-placeholder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        margin: 10px 40px 40px 0;
        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        @keyframes slide-in-bottom {
            0% {
                transform: translateY(50px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        img {
            width: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            border-radius: 25px;
            background-color: #9BCFFF;
        }



        .delete-image {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.25px;
            margin: 10px;
            font-size: 24pt;
            background-color: white;
            border-radius: 25px;
            color: #c12d2d;
            transform: scale(1);
            cursor: pointer;
            transition: transform 0.3s ease;

            body.hasHover &:hover {
                transform: scale(1.1);
            }
        } 

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #9BCFFF;
            border-radius: 25px;
            font-size: 12pt;
            font-family: 'Inter', sans-serif;
            transform: scale(1);
            transition: transform 0.4s ease, background-color 0.4s ease;
            cursor: pointer;
            height: 100%;
            width: 100%;

            span {
                margin-left: 5px;
            }
    
            svg {
                font-size: 22pt;
            }
    
            body.hasHover &:hover {
                background-color: #b4dbff;
                transform: scale(1.05);
            }
        }
    }
}

.pics-section {
    display: flex;
    flex-direction: column;

    .file-error {
        width: 320px;
    }
}

.limit-error, .file-error {
    padding-top: 5px;
    font-size: 10pt;
    color: #c12d2d;
    animation: slide-in-error 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    @keyframes slide-in-error {
        0% {
            transform: translateY(10px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

.add-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    margin: 10px 40px 0 0;
    animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    @keyframes slide-in-bottom {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &.addtl {
        margin-bottom: 0px;
    }

    .styled-input {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        background-color: #9BCFFF;
        border-radius: 25px;
        font-size: 12pt;
        font-family: 'Inter', sans-serif;
        text-align: center;
        transform: scale(1);
        transition: transform 0.4s ease, background-color 0.4s ease;
        cursor: pointer;
        aspect-ratio: 16 / 9;
        width: 320px;

        label {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 12pt;
            font-family: 'Inter', sans-serif;
            text-align: center;
            width: 100%;
            height: 100%;
            cursor: pointer;

            span {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 5px;
                    font-size: 22pt;
                }
            }
        }

        input[type="file"] {
            display: none;
            height: 0;
        }
          
        svg {
            margin-right: 5px;
            font-size: 22pt;
        }

        body.hasHover &:hover {
            background-color: #b4dbff;
            transform: scale(1.03);
        }
    }
}

.additional-pictures-wrapper {
    display: flex;
    flex-direction: column;

    .image-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 320px;
        aspect-ratio: 16 / 9;
        object-fit: cover;
        margin-top: 15px;
        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        @keyframes slide-in-bottom {
            0% {
                transform: translateY(50px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        img {
            width: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            border-radius: 25px;
        }

        .delete-image {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.25px;
            margin: 10px;
            font-size: 24pt;
            background-color: white;
            border-radius: 25px;
            color: #c12d2d;
            transform: scale(1);
            cursor: pointer;
            transition: transform 0.3s ease;

            body.hasHover &:hover {
                transform: scale(1.1);
            }
        } 
    }
}

@media only screen and (max-width: 740px) {

    .pictures-tab {
        margin-top: 25px;
        flex-direction: column;

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 22pt;
            font-weight: 700;
            margin: 0;
            margin-bottom: 25px;
        }
    
        .image-placeholder {
            width: 100%;
            max-width: 400px;
            margin: 10px 0 40px 0;
        }

        .secondary-options {
            width: 100%;
            max-width: 400px;
            margin: 10px 0 40px 0;
        }

        .pics-section {
            .file-error {
                width: 100%;
                max-width: 400px;
            }
        }

        .image-container {
            width: 100%;
            max-width: 400px;
            margin: 10px 0 40px 0;
            aspect-ratio: 16 / 9;
            height: 100%;
            margin-bottom: 0;
        }

        .add-placeholder {
            max-width: 400px;
            width: 100%;
        }

        .styled-input {
            max-width: 400px;
            width: 100%;
            aspect-ratio: 16 / 9;

            &.main-pic {
                margin: 0 0 40px 0;
            }
        }

        .image-container {
            margin-bottom: 10px;
        }
    }

    .add-placeholder {

        &.addtl {
            display: block;
            padding-bottom: 10px;
        } 
    }
}