.about-page {

    .about-info {
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
            height: 175px;
            width: 125.75px;
            margin-bottom: 25px;
        }

        .about-brand {
            font-family: 'Bitter', serif;
            font-weight: 100;
            font-size: min(36pt, 12vw);
            text-align: center;
            margin-bottom: 5px;
        }

        .version {
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 12pt;
            background-color: #4DA9FF;
            color: #EAF2FF;
            padding: 5px 15px;
            border-radius: 50px;
        }

        .masthead {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            font-size: 14pt;
            margin-top: 25px;
            text-align: center;
        }
    }

    .tribute {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: #EAF2FF;

        svg {
            font-size: 24pt;
            color: #408858;
            transform: rotate(0);
            transition: transform 0.6s ease;
            cursor: pointer;
        }

        body.hasHover &:hover {
            svg {
                transform: rotate(20deg);
            }
        }
    }

    .about-info, .dad {
        margin-bottom: 50px;
        padding-top: 50px;
        // margin-top: 125px;
    }

    .dad {
        display: flex;
        align-items: center;
        flex-direction: column;
        animation: tribute-fade 1.5s ease;

        @keyframes tribute-fade {
            0% {
                opacity: 0;
                transform: translateY(30px);
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }

        img {
            height: 100px;
            aspect-ratio: 1 / 1;
            object-fit: cover;
            border-radius: 100px;
            margin-bottom: 15px;
        }

        h3 {
            font-family: 'Bitter', serif;
            font-weight: 100;
            font-style: italic;
            text-align: center;
            font-size: min(36pt, 14vw);
            margin: 0;
        }

        .initials {
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            font-size: 60pt;
            color: #b8d3fe;
        }

        .dates {
            font-family: 'Inter', sans-serif;
            font-weight: 300;
            font-size: 18pt;
            color: #9d9d9d;
        }

        .dedication {
            font-family: 'Inter', sans-serif;
            text-align: center;
            font-weight: 200;
            margin-top: 25px;
            max-width: 555px;
        }
    }
}