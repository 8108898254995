.add-pictures {

    .no-recipe-add-pic {

        .no-recipe-text {
            font-size: 16pt;
            font-weight: 300;
            font-family: 'Bitter', sans-serif;

            #add-pic-sample {
                font-size: 16pt;
                font-weight: 500;
                font-family: 'Inter', serif;
                margin: 0 10px;
                color: #4DA9FF;
            }
        }
    }

    .add-pic-header-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
            font-size: 18pt;
        }

        button {
            display: flex;
            align-items: center;
            padding: 0 25px;
            text-decoration: none;
        }

        .back-button {
            svg {
                font-size: 22pt;
                margin-right: 5px;
            }
        }

        .cancel-edit {
            background-color: #dadada;
            transition: background-color 0.4s ease;

            body.hasHover &:hover {
                background-color: #e0e0e0;
            }
        }
    }

    .add-pic-header {
        font-weight: 500;
        color: #4DA9FF;
    }
    
    h2 {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 24pt;
    }

    p {
        color: #606060;
        margin-top: 5px;
    }

    .picture-panel {
        padding-top: 5px;

        .add-placeholder {
            width: 100%;
            max-width: 400px;

            .styled-input {
                width: calc(100% - 30px);
                max-width: 400px;
                aspect-ratio: unset;
                height: 70px;
                border-radius: 100px;
                padding: 0 15px;
            }
        }

        .available-tiles {
            margin-top: 15px;
        }
    }

    @media only screen and (max-width: 740px) {
        .picture-panel {
            padding-top: 0;
        }
    }

    .available-tiles {
        display: grid;
        grid-template-columns: 50% 50%;
        width: calc(100% - 15px);
        max-width: 385px;
        column-gap: 15px;
        row-gap: 15px;
        animation: slide-in-available-tiles 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

        @keyframes slide-in-available-tiles {
            0% {
                transform: translateY(50px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        .uploaded-tile-space {
            width: 100%;
            border-radius: 15px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
            background-color: #cadff4;

            &.image-present {
                display: flex;
                position: relative;

                .delete-image {
                    position: absolute;
                    top: 7px;
                    right: 7px;
                    padding: 0.25px;
                    font-size: 18pt;
                    background-color: white;
                    border-radius: 25px;
                    color: #c12d2d;
                    transform: scale(1);
                    cursor: pointer;
                    transition: transform 0.3s ease;

                    body.hasHover &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}