.mobileMenu {

    .mobileHeader {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            transform: rotate(0);
            transition: transform 0.3s ease;

            &.active {
                transform: rotate(45deg);
            }

            svg {
                padding-left: 0;
                color: black;
                transition: color 0.3s ease;
            }

            body.hasHover &:hover {
                svg {
                    color: #606060;
                }
            }

            
        }

        svg {
            font-size: 24pt;
            cursor: pointer;
            color: black;
            padding-left: 10px;
        }
    }

    button {
        margin-right: 20px;
    }

    .kitch-search {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .search-icon {
            font-size: 20pt;
            margin-right: 5px;
            margin-left: 5px;
            transition: color 0.3s ease;
            color: black;
            cursor: pointer;

            body.hasHover &:hover {
                color: #606060;
            }

            &.active {
                color: #4DA9FF;

                body.hasHover &:hover {
                    color: #73bbff;
                }
            }
        }
    }
    

    .mobileOverlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 950;
        height: 100vh;
        width: 100vw;
        backdrop-filter: blur(2px);
        visibility: visible;
        transition: background-color 0.6s ease, backdrop-filter 0.6s ease;

    }

    .menuContainer {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 955;
        background-color: #EAF2FF;
        box-shadow: -4px 1px 15px 0px rgba(0,0,0,0.05);
        height: 100vh;
        width: 80vw;
        max-width: 225px;
        transition: right 0.3s ease;

        display: flex;
        flex-direction: column;
        justify-items: flex-start;

        .close {
            font-size: 22pt;
            
            svg {
                padding: 25px 20px;
                cursor: pointer;
            }
        }

        hr {
            margin-left: 20px;
            width: 30%;
            border: none;
            height: 2px;
            border-radius: 20px;
            background-color: #9BCFFF;
        }

        ul {
            list-style: none;
            text-align: left;
            padding-left: 20px;
            margin: 0;


            li {
                padding: 15px 0;
                font-family: 'Inter', sans-serif;
                font-size: 12pt;

                a {
                    color: black;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    transition: color 0.25s ease;

                    svg {
                        font-size: 22pt;
                        margin-right: 10px;
                    }

                    body.hasHover &:hover {
                        color: #606060;
                    }
                }

                &.active {
                    a {
                        color: #4DA9FF;
                    }
                }

                &.add-new-drawer {

                    svg {
                        transform: rotate(0);
                        transition: transform 0.4s ease;
                    }
                }

                &.add-new-drawer.active {
                    svg {
                        transform: rotate(45deg);
                    }
                }

                body.hasHover &.active:hover {
                    a {
                        color: #73bbff;
                    }
                }
            }

            button {
                margin: 0;
            }

        }

        .add-new-nav-section {
            margin-top: 5px;
        }

        .secondary-items {
            ul {
                margin-top: 15px;
            }
        }
        
    }

    #overlayHidden {
        background-color: #ffffff00;
        backdrop-filter: blur(0px);
        visibility: hidden;

        
    }

    #containerHidden {
        right: -350px;
    }
}