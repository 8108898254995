#recipe-pdf {
    //full page scale equal to standard letter
    width: 100%;
    aspect-ratio: 8.5 / 11;
    padding: 25px;
    //content container reset
    margin-top: -88px;
    margin-left: -25px;
    
    .pdf-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        .pdf-header-left {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: flex-start;

            h1 {
                margin: 0;
                font-size: 5vw;
                font-weight: 300;
                width: 85%;
                text-align: left;
            }

            .pdf-author {
                text-align: left;
                margin-bottom: 1.25vw;
                margin-left: 0.3vw;
                font-size: 2vw;

                span {
                    font-weight: 600;
                }
            }

            .pdf-chips {
                display: flex;
                flex-wrap: wrap;
                
                .chip {
                    height: fit-content;
                    width: fit-content;
                    font-size: 1.5vw;
                    margin-right: 0.75vw;
                    margin-bottom: 0.5vh;
                    padding: 0.5vw 2vw;   
                    border-radius: 8vw;
                }
            }
        }

        .pdf-header-right {
            img {
                position: absolute;
                top: 0;
                right: 0;
                width: 10%;
            }
        }
    }
}