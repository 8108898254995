.ingredients-tab {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;

    .validation-fade {
        color: red;
        font-family: 'Inter', sans-serif;
        margin-top: 5px;

        animation: fade-in-validation 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        @keyframes fade-in-validation {
            0% {
                transform: translateY(5px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
          }
    }

    #description {
        margin-left: 320px;
    }

    @media only screen and (max-width: 740px) {
        #description {
            margin-left: 270px;
        }
    }

    @media only screen and (max-width: 600px) {
        #description {
            margin-left: 0px;
        }
    }

    .ingredients-section {
        display: flex;
        flex-direction: column;
        width: 100%;

        &.animated-section-entry {
            animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes slide-in-bottom {
                0% {
                    transform: translateY(50px);
                    height: 0;
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    height: 305px;
                    opacity: 1;
                }
            }
        }

        &.delete-ingredient-section {
            animation: delete-ingredient-section 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        @keyframes delete-ingredient-section {
            0% {
                transform: translateX(0) scaleY(1) scaleX(1);
                height: 305px;
                filter: blur(0);
                opacity: 1;
            }
            100% {
                transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                height: 0;
                filter: blur(40px);
                opacity: 0;
            }
        }

        .section-title-span {
            display: flex;
            align-items: center;
            

            input {
                margin-right: 10px;
                margin-bottom: 0;
            }
        }

        label {
            margin-bottom: 10px;
        }
    
        input, textarea, select {
            margin-bottom: 30px;
            width: 30%;
        }

        .ingredient-items {
            margin-left: 75px;
            margin-bottom: 40px;
            margin-top: 30px;

            .triple-input {
                margin-top: 10px;
                display: flex;

                &.animated-entry {
                    animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                    @keyframes slide-in-bottom {
                        0% {
                            transform: translateY(50px);
                            height: 0;
                            opacity: 0;
                        }
                        100% {
                            transform: translateY(0);
                            height: 55px;
                            opacity: 1;
                        }
                    }
                }

                &.delete-ingredient-item {
                    animation: delete-ingredient-item 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                }

                @keyframes delete-ingredient-item {
                    0% {
                        transform: translateX(0) scaleY(1) scaleX(1);
                        height: 55px;
                        filter: blur(0);
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                        height: 0;
                        filter: blur(40px);
                        opacity: 0;
                    }
                }
    
                input {
                    margin-right: 10px;
                    margin-bottom: 5px;
                    width: 100px;
    
                    &:last-of-type {
                        width: 30%;
                    }
                }
            }
        }
    }

    .add-new-item {
        font-size: 14pt;
        font-weight: 300;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: color 0.3s ease;
        width: fit-content;

        svg {
            font-size: 26pt;
            margin-right: 10px;
            color: #4DA9FF;
            transition: color 0.3s ease;
        }

        body.hasHover &:hover {
            color: #606060;

            svg {
                color: #9BCFFF;
            }
        }

        &#ingredient, &#note {
            margin-top: 15px;
        }
    }

    .remove-item {
        font-size: 26pt;
        color: #9BCFFF;
        height: 45px;
        transition: color 0.3s ease;
        cursor: pointer;

        body.hasHover &:hover {
            color: #c12d2d;
        }
    }
}

.notes-section {
    margin-top: 45px;
    width: 100%;
    animation: slide-in-note-section 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    @keyframes slide-in-note-section {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .note-items {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: calc(30% + 468px);
    
        .note-item {
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;
            animation: slide-in-note-field 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes slide-in-note-field {
                0% {
                    transform: translateY(50px);
                    height: 0;
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    height: 55px;
                    opacity: 1;
                }
            }

            &.delete-note-item {
                animation: delete-note-item 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
            }

            @keyframes delete-note-item {
                0% {
                    transform: translateX(0) scaleY(1) scaleX(1);
                    height: 55px;
                    filter: blur(0);
                    opacity: 1;
                }
                100% {
                    transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                    height: 0;
                    filter: blur(40px);
                    opacity: 0;
                }
            }

            input {
                margin-right: 10px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .ingredients-tab {
        margin-top: 25px;

        h3 {
            font-family: 'Inter', sans-serif;
            font-size: 22pt;
            font-weight: 700;
            margin: 0;
            margin-bottom: 25px;
        }

        .ingredients-section {
    
            .section-title-span {
    
                input {
                    margin-right: 10px;
                    margin-bottom: 0;
                    width: calc(100% - 95px);
                }
            }
    
            label {
                margin-bottom: 10px;
            }
        
            input, textarea, select {
                width: 100%;
                max-width: 400px;
            }
    
            .ingredient-items {
                margin-left: 35px;
                
                .triple-input {
                    width: 100%;
        
                    input {
                        width: 15%;
                        min-width: 75px;
                        margin-bottom: 5px;

                        &:last-of-type {
                            width: 60%;
                        }
                    }

                    &:first-of-type {
                        width: calc(100% - 35px);
                    }
                }
            }
        }
    
        .add-new-item {
            font-size: 14pt;
            font-weight: 300;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: color 0.3s ease;
            width: fit-content;
    
            svg {
                font-size: 26pt;
                margin-right: 10px;
                color: #4DA9FF;
                transition: color 0.3s ease;
            }
    
            body.hasHover &:hover {
                color: #606060;
    
                svg {
                    color: #9BCFFF;
                }
            }
        }
    
        .remove-item {
            font-size: 26pt;
            color: #9BCFFF;
            height: 45px;
            transition: color 0.3s ease;
            cursor: pointer;
    
            body.hasHover &:hover {
                color: #c12d2d;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .ingredients-tab {

        .ingredients-section {

        &.animated-section-entry {
            animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

            @keyframes slide-in-bottom {
                0% {
                    transform: translateY(50px);
                    height: 0;
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    height: 335px;
                    opacity: 1;
                }
            }
        }

        &.delete-ingredient-section {
            animation: delete-ingredient-section 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
        }

        @keyframes delete-ingredient-section {
            0% {
                transform: translateX(0) scaleY(1) scaleX(1);
                height: 335px;
                filter: blur(0);
                opacity: 1;
            }
            100% {
                transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                height: 0;
                filter: blur(40px);
                opacity: 0;
            }
        }
    
            .ingredient-items {
    
                .triple-input {
                    width: 100%;
                    flex-wrap: wrap;
                    margin-top: 35px;

                    &.animated-entry {
                        animation: slide-in-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                        @keyframes slide-in-bottom {
                            0% {
                                transform: translateY(50px);
                                height: 0;
                                opacity: 0;
                            }
                            100% {
                                transform: translateY(0);
                                height: 110px;
                                opacity: 1;
                            }
                        }
                    }

                &.delete-ingredient-item {
                    animation: delete-ingredient-item 0.4s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                }

                @keyframes delete-ingredient-item {
                    0% {
                        transform: translateX(0) scaleY(1) scaleX(1);
                        height: 110px;
                        filter: blur(0);
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(-1000px) scaleX(2) scaleY(0.2);
                        height: 0px;
                        filter: blur(40px);
                        opacity: 0;
                    }
                }
        
                    input {
                        width: 15%;
                        min-width: 75px;
                        margin-bottom: 5px;

                        &:last-of-type {
                            width: calc(100% - 95px);
                            margin-top: 10px;
                        }
                    }

                    &:first-of-type {
                        width: calc(100%);
                    }

                    span {
                        margin-top: 10px;
                    }
                }

                .triple-input:first-of-type {
                    margin-top: 10px;
                }
            }
        }
    
        .add-new-item {
            font-size: 14pt;
            font-weight: 300;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: color 0.3s ease;
            width: fit-content;
    
            svg {
                font-size: 26pt;
                margin-right: 10px;
                color: #4DA9FF;
                transition: color 0.3s ease;
            }
    
            body.hasHover &:hover {
                color: #606060;
    
                svg {
                    color: #9BCFFF;
                }
            }
        }
    
        .remove-item {
            font-size: 26pt;
            color: #9BCFFF;
            height: 45px;
            transition: color 0.3s ease;
            cursor: pointer;
    
            body.hasHover &:hover {
                color: #c12d2d;
            }
        }
    }
}