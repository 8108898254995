.global-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: -10px -25px 25px -25px;
    padding: 0px 25px;
    padding-top: 10px;
    position: fixed;
    top:  0px;
    height: 68px;
    width: calc(100% - 50px);
    background-color: #EAF2FF;
    z-index: 500;
    

    .branding {
        .home-link {
            display: flex;
            align-items: center;
            text-decoration: none;

            .chick {
                height: 48px;
                width: 34.5px;
                margin-right: 5px;
            }
        
            h1 {
                font-weight: 300;
                font-size: 18pt;
                color: black;
                white-space: nowrap;
            }
        }
        
    }

    .navigation {
        display: flex;
        align-items: center;
    }
    
}

@media only screen and (max-width: 740px) {
    .global-header {
        margin: -10px -15px 25px -15px;
        padding: 0px 15px;
        padding-top: 10px;
        width: calc(100% - 30px);
    }
}